import { Title, Card, Image, Table } from 'reactive-bulma';
import teams from './teams.json';
import players from './players.json';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

const pdfTeams = [
    "AP RESINAS",
    "ATLETICO ALTIVA",
    "ATLÉTICO IMPERTEX",
    "ATLETICO PPG TULTITLAN",
    "CITIKROM",
    "COLEGUITAS",
    "DEPORTIVO CRISOL",
    "DREAM TEAM KROMA-CEP",
    "FC AGA",
    "FC IZTACALCO",
    "HALCONES TICOMAN",
    "INYECTORES PPG",
    "LEONES",
    "PPG GOLDEN",
    "REAL COMEX TEPEXPAN",
    "REAL CUAUTITLAN",
    "REAL IZTACALCO",
    "SELECCIÓN PPG",
    "VINIMEX TEPEXPAN",
    "EMPODERADOS PPG FC",
    "ONE PPG FC",
    "REAL CSV (Metokote)",
    "SOMOS GBS",
    "UNION PPG",
    "CHIWAS TEAM",
    "CIMARRONES TJ",
    "COMEX FC",
    "GUANATOS FC",
    "HERMOSILLO FC",
    "TIBURONES DE MINATITLAN",
];

export const Teams = () => {
    const [teamDetails, setTeamDetails] = useState();
    const [isMobile] = useState(!window.matchMedia("(min-width: 768px)").matches);
    const [isDisabled, setIsDisabled] = useState(true);
    let pdfLink = null;

    useEffect(() => {
        if(teamDetails){
            pdfLink=`/assets/schedules/${teamDetails.teamName}.pdf`
            
            if(pdfTeams.includes(teamDetails.teamName)){
                setIsDisabled(false);
                
            } else{
                pdfLink = null;
                setIsDisabled(true);
            }
        }
    }, [teamDetails]);

    const makeTeamPlayers = () => (
        <Card
        content={<>
            <a 
                className='button is-danger is-outlined mb-2'
                style={{width:"100%"}}
                href={isDisabled ? false : `/assets/schedules/${teamDetails.teamName}.pdf`}
                download={isDisabled ? false : `itinerario-${teamDetails.id}.pdf`}
                disabled={isDisabled}
            >
                <FontAwesomeIcon style={{ fontSize:"1.3rem", margin:"0 1rem" }} icon={faFilePdf} /> Descarga tu itinerario
            </a>

            <Table
                    body={players.reduce((team, player) => (
                        player.team === teamDetails.teamName ?
                            [...team, { 
                                isSelected: false,
                                listOfCells: [
                                    { content: player.number },
                                    { content: `${player.firstName} ${player.lastName}` },
                                ]
                            }]
                            : team
                    ), [])}
                    head={[
                        { content: '# Número' },
                        { content: 'Nombre' },
                    ]}
                />
            </>}
            headerText={<span>{teamDetails.teamName} <span class="tag is-light">{teamDetails.id}</span> <span class="tag is-light is-info">{teamDetails.origin}</span></span>}
        />
    );

    return(<div className="has-text-dark" style={{
        position: "absolute",
        width:"100%",
        top: isMobile ? "11rem":"8rem",
        left:"0",
        color: "#000",
        textAlign:"left"
    }}>
        <div className='container'>
            <Title
                style={{ fontSize: "2rem" }}
                secondary={{
                    text:<span style={{fontSize:"1.45rem"}}>Click en un equipo para conocer los jugadores y su itinerario.</span>,
                    type: 'subtitle'
                }}
                main={{
                    text: 'Equipos',
                    type: 'title'
                }}
            />
            
            <div className='columns is-multiline mt-5'>
                {teams.map(team => (
                    <div 
                        className='column is-one-third'
                        onClick={() => setTeamDetails(team)}
                    >
                        <Card
                            style={{ cursor:"pointer" }}
                            cssClasses="has-text-dark"
                            headerText={<span>{team.teamName} <span class="tag is-light">{team.id}</span> <span class="tag is-light is-info">{team.origin}</span></span>}
                            content={(<>
                                <Image src={`/assets/shirts/${team.teamName}.jpeg`}
                                    style={{
                                        maxHeight: "80vh",
                                        height:"15rem",
                                        width:"auto",
                                        margin:"auto",
                                    }}
                                />
                            </>)}
                        />
                    </div>
                ))}
            </div>

            {teamDetails && <div class={`modal is-active`}>
                <div class="modal-background"></div>
                <div class="modal-content">
                    {makeTeamPlayers()}
                </div>
                
                <button class="modal-close is-large" aria-label="close" onClick={() => setTeamDetails()}/>
            </div>}
        </div>
    </div>);
}