import { Title, Card, Image, Table } from 'reactive-bulma';
import teams from './teams.json';
import players from './players.json';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faImage, faMap } from '@fortawesome/free-solid-svg-icons';
import { tabStyle } from './matches';
import { Gallery } from '../../../components/gallery';
import { height } from '@fortawesome/free-brands-svg-icons/fa42Group';

export const Rules = () => {
    const [activeTab, setActiveTab] = useState("map");
    const [isMobile] = useState(!window.matchMedia("(min-width: 768px)").matches);
    

    return(<div className="has-text-dark" style={{
        position: "absolute",
        width:"100%",
        top: isMobile ? "11rem":"8rem",
        left:"0",
        color: "#000",
        textAlign:"left"
    }}>
        <div className='container'>

        
            <a 
                className='button is-danger is-outlined mb-5'
                href="/assets/Reglamento Intersite 2024.pdf" download={`mapa-fmf.jpg`}
            >
                <FontAwesomeIcon style={{ fontSize:"1.3rem", margin:"0 1rem" }} icon={faFilePdf} /> Descarga el reglamento.
            </a>

            <Card
            style={{ height:"70vh" }}
                content={
                    <iframe 
                        style={{
                            height:"65vh",
                        }}
                        width="100%"
                        src="/assets/Reglamento Intersite 2024.pdf" 
                        type="application/pdf"
                    />
                }
            />


        </div>
    </div>);
}