import axios from 'axios';
import { ApiConfig } from './api.config';
import { getPublicKey } from '../etc/api';

export class Api {
    api;
    auth_token = "";

    constructor(auth_token  = ""){
        let headers = { ...ApiConfig.HEADERS };
        if(auth_token) headers['Authorization'] = auth_token;
        else headers['Authorization'] = getPublicKey()

        this.api = axios.create({
            baseURL: ApiConfig.URL,
            headers
        });
    }
}