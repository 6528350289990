import { Hero, Title, Card, FormField, Button } from 'reactive-bulma'
import { BrandScreen } from '../../components/brand-screen';
import { useState } from 'react';
import { Roles } from '../../etc/roles';
import { getPublicKey } from '../../etc/api';

export const HomePage =  ()=> {
    const [credentials, setCredentials] = useState({});
    const [helper, setHelper] = useState({});

    const credentialsList =[
        {
            "name": "EDGAR MENDOZA",
            "user": "edgar_si@live.com.mx",
            "password": "EnWgp94R8u",
            "role": [Roles.REFEREE]
        },
        {
            "name": "SAUL TADEUS LOPEZ GARCIA",
            "user": "stadeus.stlg@gmail.com",
            "password": "XBtTC8D3uY",
            "role": [Roles.REFEREE]
        },
        {
            "name": "ROGELIO IVAN DEL VALLE ORTIZ",
            "user": "lavalleh.21k@gmail.com",
            "password": "ri13SgMnPN",
            "role": [Roles.REFEREE]
        },
        {
            "name": "MIGUEL CHRISTIAN HERNÁNDEZ CASTILLO",
            "user": "christian20685@hotmail.com",
            "password": "lX5KmJtAig",
            "role": [Roles.REFEREE]
        },
        {
            "name": "JAVIER MISAEL RUIZ SOLÍS",
            "user": "misaruiz1209@gmail.com",
            "password": "b79FYrVvhK",
            "role": [Roles.REFEREE]
        },
        {
            "name": "JOSÉ ROBERTO GARCÍA GAONA",
            "user": "R10gia@hotmail.com",
            "password": "NoOn48r0mH",
            "role": [Roles.REFEREE]
        },
        {
            "name": "SERGIO MAURICIO AGUILAR RODRÍGUEZ",
            "user": "aguilarrodriguez.sergio6@gmail.com",
            "password": "zN4khmDMTW",
            "role": [Roles.REFEREE]
        },
        {
            "name": "CRISTIÁN GONZÁLEZ MARTÍNEZ",
            "user": "christian_gm21@hotmail.com",
            "password": "MxeU70sWNg",
            "role": [Roles.REFEREE]
        },
        {
            "name": "MARAT ALBERTO ALFARO ROSETE",
            "user": "maratneymar16@gmail.com",
            "password": "ogLeV58Uw7",
            "role": [Roles.REFEREE]
        },
        {
            "name": "JOSÉ ALFREDO ROSETE BECERRIL",
            "user": "bastian23keks@gmail.com",
            "password": "XBvL7HAFzx",
            "role": [Roles.REFEREE]
        },
        {
            "name": "ARELY GARCÍA SALAS",
            "user": "are.lcag11@gmail.com",
            "password": "Z9iKjmQbLf",
            "role": [Roles.REFEREE]
        },        
        {
            "name": "Monserrat",
            "user": "monher0406@gmail.com",
            "password": "axCd2mTrpP",
            "role": [Roles.ORGANIZER, Roles.PHOTOGRAPHER, Roles.REFEREE]
        },
        {
            "name": "Alyson",
            "user": "alyson@hamable.com",
            "password": "GcsmPoEY2B",
            "role": [Roles.PHOTOGRAPHER]
        },
        {
            "name": "Maribel",
            "user": "maribel@hamable.com",
            "password": "rcsmPoEY2D",
            "role": [Roles.ORGANIZER, Roles.REFEREE, Roles.PHOTOGRAPHER]
        },
        {
            "name": "Astrid",
            "user": "astrid@hamable.com",
            "password": "Te916hbpB7",
            "role": [Roles.ORGANIZER]
        },
        {
            "name": "Oswaldo",
            "user": "ovgonzalez.4@icloud.com",
            "password": "tNH4fvWzpU",
            "role": [Roles.REFEREE]
        },
        {
            "name": "Oswaldo",
            "user": "test@sporti.com",
            "password": "google123",
            "role": [Roles.REFEREE]
        },
    ]

    const handleCredentials = ({ target: { value }}, key) =>
        setCredentials({ ...credentials, [key]: value })

    const logIn = () => {
        const session = credentialsList.filter(credential => credentials.user.toLowerCase() === credential.user)[0];
        
        if(session && credentials.password === session.password){
            delete session.password;

            setHelper({});
            sessionStorage.setItem("credentials", JSON.stringify({
                ...session, authorization: getPublicKey()
            }))

            window.location.assign("/#/ppg");
            window.scrollTo(0, 0);
        } else {
            setHelper({
                color: 'is-danger',
                text: 'Usuario o contraseña invalidos'
            })
        }
    }

    return (
        <>
            <BrandScreen child={
                <Title main={{text: 'Minuto a minuto, en vivo', type: 'title'}} secondary={{text: 'Tu evento deportivo, online...', type: 'subtitle'}}/>
            }></BrandScreen>

            <Hero
                body={
                <section className="container has-text-dark">
                    
                    <Card
                    style={{
                        margin:"auto",
                        maxWidth:"400px"
                    }}
                    content={
                        <>
                            <FormField
                                inputControlConfig={{
                                    inputConfig: {
                                        type: 'text',
                                        color: helper.color,
                                        onChange: (e) => handleCredentials(e, 'user')
                                    }
                                }}
                                labelText="Usuario"
                            />
                            <FormField
                                inputControlConfig={{
                                    inputConfig: {
                                        type: 'password',
                                        color: helper.color,
                                        onChange: (e) => handleCredentials(e, 'password')
                                    }
                                }}
                                labelText="Contraseña"
                                helperConfig={{
                                    text: helper.text,
                                }}
                            />

                            <Button
                                style={{ marginTop: "1rem", width:"100%"}}
                                color="is-info"
                                text="Iniciar Sesión"
                                onClick={logIn}
                            />
                        </>
                    }
                />

                </section>}
                size="is-fullheight"
                color='is-light'
            />
        </>
    );
}