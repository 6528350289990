import { Hero, Breadcrumbs } from 'reactive-bulma'


export const BrandScreen = ({
    child,
    navItems = [],
}) => {
  return (
    <>
      <section
        style={{
          minHeight: '100%',
          minWidth: '100%',
          background: 'linear-gradient(315deg, rgba(140,82,255,1) 0%, rgba(94,23,235,1) 64%, rgba(41,109,253,1) 100%)',
          position: 'absolute'
        }}
      >
      </section>
      <Hero
        header={
            <Breadcrumbs style={{
                position: "relative",
                margin: "1rem 2rem",
                fontWeight: "bold",
                fontSize: "2rem"
            }} items={[
                ...navItems,
                {isActiveItem: true, text: 'Sporti'}
            ]}/>
        }
        body={
          <section className="container has-text-light">
            {child}
          </section>}
        size="is-fullheight"
        color='is-primary'
      />
    </>
  );
}


