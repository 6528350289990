import React from 'react';
import { Title, Image, Table, Card } from 'reactive-bulma';
import SoccerField from '../../../assets/field.jpg'
import fmfa from '../../../assets/fmfa.jpeg';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faLocationDot, faFutbol, faTrophy, faClock, faPeopleGroup, faRankingStar, faMedal, faMap } from '@fortawesome/free-solid-svg-icons';

const getTime = () => {
    let date_now = new Date();
    let date_future = new Date("2024-09-22T15:00:00.000Z");

    // get total seconds between the times
    let delta = Math.abs(date_future - date_now) / 1000;

    // calculate (and subtract) whole days
    let days = Math.floor(delta / 86400);
    delta -= days * 86400;

    // calculate (and subtract) whole hours
    let hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    // calculate (and subtract) whole minutes
    let minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    // what's left is seconds
    let seconds = delta % 60;  // in theory the modulus is not required

    return { days, hours, minutes, seconds }
}

export const intersite = (
    <Card
        content={
            <Table
                body={[
                    { 
                        isSelected: false,
                        listOfCells: [
                            { content: 'Registro y entrega de uniformes' },
                            { content: 'El capitán de tu equipo debe recoger los uniformes en la mesa de registro' },
                            { content: '30' },
                            { content: '8:00-9:00 h' },
                            { content: 'Zona de acceso y mesa de registro' },
                        ]
                    },
                    { 
                        isSelected: false,
                        listOfCells: [
                            { content: 'Preparación para el desfile' },
                            { content: 'Favor de presentarse en equipo en la cancha 4' },
                            { content: '30' },
                            { content: '8:50-9:00 h' },
                            { content: '4' },
                        ]
                    },
                    { 
                        isSelected: false,
                        listOfCells: [
                            { content: 'Inauguración' },
                            { content: '' },
                            { content: '30' },
                            { content: '9:00-9:30 h' },
                            { content: '4, 5 y 6' },
                        ]
                    },
                    { 
                        isSelected: false,
                        listOfCells: [
                            { content: 'Primera ronda' },
                            { content: '30' },
                            { content: '30' },
                            { content: '9:30-11:30 h' },
                            { content: '1-10' },
                        ]
                    },
                    { 
                        isSelected: false,
                        listOfCells: [
                            { content: 'Segunda ronda' },
                            { content: '8' },
                            { content: '16' },
                            { content: '11:40-12:20 h' },
                            { content: '1-8' },
                        ]
                    },
                    { 
                        isSelected: false,
                        listOfCells: [
                            { content: 'Apertura de la zona de alimentos' },
                            { content: 'Favor de estar atentos a la pestaña de partidos para conocer el horario de comida de tu equipo' },
                            { content: '' },
                            { content: 'A partir de 12:30 h. hasta las 17:00 h' },
                            { content: 'Zona de alimentos' },
                        ]
                    },
                    { 
                        isSelected: false,
                        listOfCells: [
                            { content: 'Cuartos' },
                            { content: '4' },
                            { content: '8' },
                            { content: '14:00-14:40 h' },
                            { content: '1, 3, 4 y 6' },
                        ]
                    },
                    { 
                        isSelected: false,
                        listOfCells: [
                            { content: 'Semifinal' },
                            { content: '2' },
                            { content: '4' },
                            { content: '14:40-15:20 h' },
                            { content: '4-6' },
                        ]
                    },
                    { 
                        isSelected: false,
                        listOfCells: [
                            { content: 'Tercer lugar' },
                            { content: '1' },
                            { content: '2' },
                            { content: '15:20-16:00 h' },
                            { content: '4' },
                        ]
                    },
                    { 
                        isSelected: false,
                        listOfCells: [
                            { content: 'Final' },
                            { content: '1' },
                            { content: '2' },
                            { content: '15:20-16:00 h' },
                            { content: '6' },
                        ]
                    },
                    { 
                        isSelected: false,
                        listOfCells: [
                            { content: 'Premiación y cierre' },
                            { content: '' },
                            { content: '30' },
                            { content: '16:00 - 16:40 h' },
                            { content: '4,5,6' },
                        ]
                    },
                    { 
                        isSelected: false,
                        listOfCells: [
                            { content: 'Abordaje de autobuses' },
                            { content: '' },
                            { content: '30' },
                            { content: '16:40 - 17:00 h' },
                            { content: 'Zona de acceso' },
                        ]
                    },
                ]}
                head={[
                    { content: 'Ronda' },
                    { content: 'Número de partidos' },
                    { content: 'Número de equipos' },
                    { content: 'Horario' },
                    { content: 'Número de canchas' },
                ]}
            />
        }
        headerText="Torneo Intersite"
    />
);

export const minicopa = (
    <Card
        content={
            <Table
                body={[
                    { 
                        isSelected: false,
                        listOfCells: [
                            { content: 'Primera ronda' },
                            { content: '7' },
                            { content: '14' },
                            { content: '12:30-13:10h' },
                            { content: '1-7' },
                        ]
                    },
                    { 
                        isSelected: false,
                        listOfCells: [
                            { content: 'Semifinales' },
                            { content: '2' },
                            { content: '4' },
                            { content: '13:20-14:00h' },
                            { content: '1 y 3' },
                        ]
                    },
                    { 
                        isSelected: false,
                        listOfCells: [
                            { content: 'Tercer Lugar' },
                            { content: '1' },
                            { content: '2' },
                            { content: '15:20-16:00h' },
                            { content: '1' },
                        ]
                    },
                    { 
                        isSelected: false,
                        listOfCells: [
                            { content: 'Final' },
                            { content: '1' },
                            { content: '2' },
                            { content: '15:20-16:00h' },
                            { content: '3' },
                        ]
                    },
                    { 
                        isSelected: false,
                        listOfCells: [
                            { content: 'Premiación y cierre' },
                            { content: '' },
                            { content: '30' },
                            { content: '16:00-16:40 h' },
                            { content: '4,5,6' },
                        ]
                    },
                ]}
                head={[
                    { content: 'Ronda' },
                    { content: 'Número de partidos' },
                    { content: 'Número de equipos' },
                    { content: 'Horario' },
                    { content: 'Número de canchas' },
                ]}
            />
        }
        headerText="Minicopa"
    />
);

const glassCardStyle = { borderTopLeftRadius:"10% 17%", borderTopRightRadius:"10% 17%", borderEndEndRadius:"10% 17%", borderEndStartRadius:"10% 17%", background:"#1878a94a" }

export const Cup = () => {
    const [remaining, setRemaining] = useState(getTime());
    const [showModal, setShowModal] = useState();
    const [isMobile] = useState(!window.matchMedia("(min-width: 768px)").matches);

    useEffect(() => {
        const timer = (remaining.seconds + remaining.minutes + remaining.hours + remaining.days) > 0 && setInterval(() => setRemaining(getTime()), 1000);
        return () => clearInterval(timer);
      }, [remaining]);

    const timeLineStyle = {
        borderLeft: "1px #a5a5a5 solid",
        padding: "0 1rem"
    }

    const mapa = (
        <Card
            content={
                <Image src="/assets/fmfmapa.jpg"
                    style={{
                        maxHeight: "80vh",
                        width:"auto",
                        margin:"auto",
                    }}
                />
            }
            headerText="Conoce la Federación Mexicana de Fútbol Campus toluca"
        />
    );

    const modalSections = {
        intersite,
        minicopa,
        mapa
    }

    return(<div
        style={{
            position: "absolute",
            width:"100%",
            top: "0",
            left:"0",
        }}
    >
        <div
            className="has-text-white"
            style={{ 
                fontSize:"1.2rem",
                backgroundImage:`url(${SoccerField})`,
                width:"100%",
                height: "100vh",
                minHeight:  isMobile ? "1150px" : undefined,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundAttachment: "fixed"
            }}
        >
            <div style={{
                position: "relative",
                background:"#ffffff24",
                width:"100%",
                height:"100%",
                paddingLeft:"1rem",
                paddingRight:"1rem",
                paddingTop: isMobile ? "7rem" : "5rem",
            }}>
                <Image src="/assets/LogoIntersite2024.png"
                    style={{
                        maxHeight:"55vh",
                        maxWidth: "55vh",
                        margin:"auto",
                        background: "radial-gradient(circle, rgb(153 153 153 / 35%) 0%, rgb(165 165 165 / 29%) 50%, rgba(255, 255, 255, 0) 73%)"
                    }}
                />
                
                <Title
                    main={{
                        text: '22 de septiembre de 2024',
                        type: 'title'
                    }}
                    secondary={{
                        text: 'Vive tus Sueños: Un torneo, un equipo, un triunfo.',
                        type: 'subtitle'
                    }}
                />

                <div class="columns mt-6">
                    <div class="column columns px-6">
                        <div className='column mx-2 mb-4' style={glassCardStyle}>
                            <Title
                                main={{
                                    text: 'Días',
                                    type: 'subtitle'
                                }}
                                secondary={{
                                    text: 0,
                                    type: 'title'
                                }}
                            />
                        </div>
                        
                        <div className='column mx-2 mb-4' style={glassCardStyle}>
                            <Title
                                main={{
                                    text: 'Horas',
                                    type: 'subtitle'
                                }}
                                secondary={{
                                    text: 0,
                                    type: 'title'
                                }}
                            />
                        </div>

                        <div className='column mx-2 mb-4' style={glassCardStyle}>
                            <Title
                                main={{
                                    text: 'Minutos',
                                    type: 'subtitle'
                                }}
                                secondary={{
                                    text: 0,
                                    type: 'title'
                                }}
                            />
                        </div>

                        <div className='column mx-2 mb-4' style={glassCardStyle}>
                            <Title
                                main={{
                                    text: 'Segundos',
                                    type: 'subtitle'
                                }}
                                secondary={{
                                    text: 0,
                                    type: 'title'
                                }}
                            />
                        </div>

                    </div>
                    <div class="column">
                        <button
                            style={{ fontWeight:"bold" }}
                            className='button is-white is-outlined'
                            onClick={() => window.open('https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20240922T150000Z%2F20240922T230000Z&details=Torneo%20Intersite%202024%20en%20la%20Federaci%C3%B3n%20Mexicana%20de%20F%C3%BAtbol%20Asociaci%C3%B3n%20A.C.%0A%0AVive%20tus%20Sue%C3%B1os%3A%20Un%20torneo%2C%20un%20equipo%2C%20un%20triunfo.&location=Federaci%C3%B3n%20Mexicana%20de%20F%C3%BAtbol%20Asociaci%C3%B3n%2C%20A.%20C.%2C%20Av.%20Arboledas%20No.%20101%20San%20Mateo%20Otzacatipan%2C%20Manzana%20003%2C%20Santin%2C%2050210%20Toluca%20de%20Lerdo%2C%20M%C3%A9x.%2C%20M%C3%A9xico&text=Torneo%20Intersite%202024','_blank')}
                        >
                            <FontAwesomeIcon icon={faCalendar} />&nbsp;
                            AGREGAR A MI CALENDARIO
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div className='columns has-text-white' style={{ marginTop:"0px"}}>
                <div class="column has-background-dark pt-6 px-6" style={{ 
                    height: isMobile ? "51rem":"40rem",
                }}>
                    <FontAwesomeIcon style={{ color:"#fcaa09", fontSize:"5rem", marginBottom:"2rem", marginTop:"3rem" }} icon={faTrophy} />

                    <Title
                        main={{
                            text: 'Torneo',
                            type: 'title'
                        }}
                    />

                    <table className='has-text-left has-text-grey-lighter' style={{ fontSize:"1.1rem", fontWeight:"200"}}>
                        <tr>
                            <td><FontAwesomeIcon icon={faPeopleGroup} style={{ fontSize: "1.3rem", margin:"0 1rem"}}/> </td>
                            <td style={timeLineStyle}>Participarán 30 equipos divididos en 10 grupos de 3 equipos cada uno. <br/><br/></td>
                        </tr>
                        <tr>
                            <td><FontAwesomeIcon icon={faFutbol} style={{ fontSize: "1.3rem", margin:"0 1rem"}}/> </td>
                            <td style={timeLineStyle}>Durante la fase de grupos, cada equipo jugará 2 partidos. <br/><br/></td>
                        </tr>
                        <tr>
                            <td><FontAwesomeIcon icon={faRankingStar} style={{ fontSize: "1.3rem", margin:"0 1rem"}}/></td>
                            <td style={timeLineStyle}>Clasificarán a segunda ronda, los primeros lugares de cada grupo y los mejores 6 segundos lugares.<br/><br/></td>
                        </tr>
                        <tr>
                            <td><FontAwesomeIcon icon={faTrophy} style={{ fontSize: "1.3rem", margin:"0 1rem"}}/></td>
                            <td style={timeLineStyle}>Posteriormente se llevarán a cabo cuartos, semifinales y final. <br/><br/></td>
                        </tr>
                    </table>

                    <>
                        <button
                            style={{ fontWeight:"bold" }}
                            className='button is-warning is-outlined'
                            onClick={() => setShowModal("intersite")}
                        >
                            <FontAwesomeIcon icon={faClock} />&nbsp;
                            CONOCE LOS HORARIOS
                        </button>

                        <div class={`modal ${showModal==="intersite" && 'is-active'}`}>
                            <div class="modal-background"></div>
                            <div class="modal-content">
                                {modalSections[showModal]}
                            </div>
                            
                            <button class="modal-close is-large" aria-label="close" onClick={() => setShowModal()}/>
                        </div>
                    </>
                </div>
                <div class="column pt-6 px-6 has-background-info" style={{
                    position:"relative",
                    height: "40rem",
                }}>
                        <FontAwesomeIcon style={{ fontSize:"5rem", marginBottom:"2rem", marginTop:"3rem" }} icon={faMedal} />
                    <Title
                        main={{
                            text: 'Minicopa',
                            type: 'title'
                        }}
                    />

                    <table className='has-text-left has-text-white' style={{ fontSize:"1.1rem", fontWeight:"200"}}>
                        <tr>
                            <td><FontAwesomeIcon icon={faFutbol} style={{ fontSize: "1.3rem", margin:"0 1rem"}}/> </td>
                            <td style={timeLineStyle}>Los 14 equipos que no avanzan en la primera ronda del torneo, tienen la oportunidad de participar en una mini copa.<br/><br/></td>
                        </tr>
                        <tr>
                            <td><FontAwesomeIcon icon={faMedal} style={{ fontSize: "1.3rem", margin:"0 1rem"}}/> </td>
                            <td style={timeLineStyle}>Se enfrentarán a un partido de eliminación directa, clasifican los 4 mejores a semifinales y final de copa.<br/><br/></td>
                        </tr>
                    </table>

                    <>
                        <button
                            style={{ fontWeight:"bold" }}
                            className='button is-white is-outlined'
                            onClick={() => setShowModal("minicopa")}
                        >
                            <FontAwesomeIcon icon={faClock} />&nbsp;
                            CONOCE LOS HORARIOS
                        </button>

                        <div class={`modal ${showModal==="minicopa" && 'is-active'}`}>
                            <div class="modal-background"></div>
                            <div class="modal-content">
                                {modalSections[showModal]}
                            </div>
                            
                            <button class="modal-close is-large" aria-label="close" onClick={() => setShowModal()}/>
                        </div>
                    </>
                </div>
        </div>

        <div className='columns has-text-white' style={{ 
            marginTop:"",
            background: `url(${fmfa})`, 
            backgroundSize: "cover",
            backgroundPosition: "top",
            backgroundAttachment: "fixed",
            height:"90vh",
        }}>
            <div style={{
                position: "relative",
                background:"#0009",
                width:"100%",
                height:"100%",
                paddingTop: "15rem",
            }}>
                    <Title
                        main={{
                            text: 'Ubicación',
                            type: 'title'
                        }}
                        secondary={{
                            text: "Av. Arboledas No. 101, Col. San Mateo Otzacatipan, C.P. 50200, Toluca, Estado de México",
                            type: 'subtitle'
                        }}
                    />

                    <button
                        style={{ fontWeight:"bold" }}
                        className='button is-white is-outlined'
                        onClick={() => window.open('https://maps.app.goo.gl/t6by2X7bhA7ibKgK8','_blank')}
                    >
                        <FontAwesomeIcon icon={faLocationDot} />&nbsp;
                        NAVEGAR AQUÍ
                    </button>

                    <>
                        <button
                            style={{ fontWeight:"bold" }}
                            className='button is-white is-outlined ml-4 is-hidden'
                            onClick={() => setShowModal("mapa")}
                        >
                            <FontAwesomeIcon icon={faMap} />&nbsp;
                            CONOCE LA FMFA
                        </button>

                        <div class={`modal ${showModal==="mapa" && 'is-active'}`}>
                            <div class="modal-background"></div>
                            <div class="modal-content" style={{ width:"90vw" }}>
                                {modalSections[showModal]}
                            </div>
                            
                            <button class="modal-close is-large" aria-label="close" onClick={() => setShowModal()}/>
                        </div>
                    </>
            </div>
        </div>

        <div className='columns has-text-white' style={{ marginTop:"-12px"}}>
                <div class="column has-background-dark pt-6 px-6" style={{ 
                    height:"30vh",
                }}>
                    <b>Sporti App</b><br></br>

                    2024, PPG, Todos los derechos reservados.<br/>
                    Área: Eventos Institucionales<br/>
                    Dirección de Asuntos Corporativos y Servicios Administrativos
                </div>
        </div>
    </div>);
}