import { Title, Card, Image, Table, Input, Hero } from 'reactive-bulma';
import teams from './teams.json';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faBook, faCheckCircle, faClipboard, faClipboardCheck, faClock, faCross, faDoorOpen, faFutbol, faInfo, faList, faMinusCircle, faPause, faPlay, faPlus, faPlusCircle, faRegistered, faSave, faShieldDog, faShieldHalved, faTicket, faX } from '@fortawesome/free-solid-svg-icons';
import { minicopa, intersite } from './cup';
import { useSession } from '../../../lib/session.helper';
import { SportiService } from './providers/service';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Roles } from '../../../etc/roles';

export const tabStyle = {
    "alignItems": "center",
    "display": "flex",
    "justifyContent": "center",
    "marginBottom": "-1px",
    "padding": ".5em 1em",
    "verticalAlign": "top",
}

const MockMatches = [
    {
      "teamA": "TIBURONES DE MINATITLAN",
      "teamAGoals": 4,
      "teamBGoals": 2,
      "teamB": "REAL IZTACALCO",
      "stage": "OCTAVOS",
      "cup": "COPA"
    },
    {
      "teamA": "FC AGA",
      "teamAGoals": 1,
      "teamBGoals": 0,
      "teamB": "DEPORTIVO CRISOL",
      "stage": "OCTAVOS",
      "cup": "COPA"
    },
    {
      "teamA": "SELECCIÓN PPG",
      "teamAGoals": 1,
      "teamBGoals": 0,
      "teamB": "UNION PPG",
      "stage": "OCTAVOS",
      "cup": "COPA"
    },
    {
      "teamA": "REAL CSV (metokote)",
      "teamAGoals": 1,
      "teamBGoals": 0,
      "teamB": "CITIKROM",
      "stage": "OCTAVOS",
      "cup": "COPA"
    },
    {
      "teamA": "SOMOS GBS",
      "teamAGoals": 3,
      "teamBGoals": 0,
      "teamB": "EMPODERADOS PPG FC",
      "stage": "OCTAVOS",
      "cup": "COPA"
    },
    {
      "teamA": "ONE PPG FC",
      "teamAGoals": 1,
      "teamBGoals": 0,
      "teamB": "REAL COMEX TEPEXPAN",
      "stage": "OCTAVOS",
      "cup": "COPA"
    },
    {
      "teamA": "VINIMEX TEPEXPAN",
      "teamAGoals": 11,
      "teamBGoals": 0,
      "teamB": "ATLETICO ALTIVA",
      "stage": "OCTAVOS",
      "cup": "COPA"
    },
    {
      "teamA": "COMEX FC",
      "teamAGoals": 2,
      "teamBGoals": 1,
      "teamB": "ATLETICO IMPERTEX",
      "stage": "OCTAVOS",
      "cup": "COPA"
    },
    {
      "teamA": "CIMARRONES TJ",
      "teamAGoals": 0,
      "teamBGoals": 3,
      "teamB": "FC IZTACALCO",
      "stage": "OCTAVOS",
      "cup": "MINI COPA"
    },
    {
      "teamA": "CHIWAS TEAM",
      "teamAGoals": 0,
      "teamBGoals": 4,
      "teamB": "DREAM TEAM KROMA-CEP",
      "stage": "OCTAVOS",
      "cup": "MINI COPA"
    },
    {
      "teamA": "COLEGUITAS",
      "teamAGoals": 5,
      "teamBGoals": 1,
      "teamB": "REAL CUAUTITLAN",
      "stage": "OCTAVOS",
      "cup": "MINI COPA"
    },
    {
      "teamA": "LEONES",
      "teamAGoals": 1,
      "teamBGoals": 0,
      "teamB": "INYECTORES PPG",
      "stage": "OCTAVOS",
      "cup": "MINI COPA"
    },
    {
      "teamA": "EMPODERADOS PPG FC",
      "teamAGoals": 4,
      "teamBGoals": 1,
      "teamB": "CHIWAS",
      "stage": "OCTAVOS",
      "cup": "MINI COPA"
    },
    {
      "teamA": "HALCONES TICOMAN",
      "teamAGoals": 1,
      "teamBGoals": 0,
      "teamB": "GUANATOS FC",
      "stage": "OCTAVOS",
      "cup": "MINI COPA"
    },
    {
      "teamA": "PP GOLDEN",
      "teamAGoals": 3,
      "teamBGoals": 1,
      "teamB": "ATLETICO PPG TULTITLÁN",
      "stage": "OCTAVOS",
      "cup": "MINI COPA"
    },
    {
      "teamA": "SELECCIÓN PPG",
      "teamAGoals": 4,
      "teamBGoals": 2,
      "teamB": "SOMOS GBS",
      "stage": "CUARTOS",
      "cup": "COPA"
    },
    {
      "teamA": "ONE PPG",
      "teamAGoals": 3,
      "teamBGoals": 0,
      "teamB": "VINIMEX TEPEXPAN",
      "stage": "CUARTOS",
      "cup": "COPA"
    },
    {
      "teamA": "REAL CSV (metokote)",
      "teamAGoals": 2,
      "teamBGoals": 1,
      "teamB": "FC AGA",
      "stage": "CUARTOS",
      "cup": "COPA"
    },
    {
      "teamA": "TIBURONES DE MINATITLAN",
      "teamAGoals": 4,
      "teamBGoals": 0,
      "teamB": "COMEX FC",
      "stage": "CUARTOS",
      "cup": "COPA"
    },
    {
      "teamA": "SELECCIÓN PPG",
      "teamAGoals": 4,
      "teamBGoals": 0,
      "teamB": "REAL CSV (metokote)",
      "stage": "SEMIS",
      "cup": "COPA"
    },
    {
      "teamA": "ONE PPG",
      "teamAGoals": 2,
      "teamBGoals": 0,
      "teamB": "TIBURONES DE MINATITLAN",
      "stage": "SEMIS",
      "cup": "COPA"
    },
    {
      "teamA": "FC IZTACALCO",
      "teamAGoals": 3,
      "teamBGoals": 2,
      "teamB": "DREAM TEAM KROMA-CEP",
      "stage": "SEMIS",
      "cup": "MINI COPA"
    },
    {
      "teamA": "COLEGUITAS",
      "teamAGoals": 1,
      "teamBGoals": 0,
      "teamB": "EMPODERADOS PPG FC",
      "stage": "SEMIS",
      "cup": "MINI COPA"
    },
    {
      "teamA": "ONE PPG",
      "teamAGoals": 3,
      "teamBGoals": 0,
      "teamB": "SELECCIÓN PPG",
      "stage": "FINAL",
      "cup": "COPA"
    },
    {
      "teamA": "REAL CSV (metokote)",
      "teamAGoals": 1,
      "teamBGoals": 2,
      "teamB": "TIBURONES DE MINATITLAN",
      "stage": "TERCERO",
      "cup": "COPA"
    },
    {
      "teamA": "FC IZTACALCO",
      "teamAGoals": 0,
      "teamBGoals": 4,
      "teamB": "COLEGUITAS",
      "stage": "FINAL",
      "cup": "MINI COPA"
    },
    {
      "teamA": "EMPODERADOS PPG FC",
      "teamAGoals": 4,
      "teamBGoals": 0,
      "teamB": "DREAM TEAM",
      "stage": "TERCERO",
      "cup": "MINI COPA"
    }
  ]

const getMockCup = () => {
    return MockMatches.filter(matchMocked => matchMocked.cup === "COPA").reduce((stages, match) => {
        const { teamA, teamB, teamAGoals, teamBGoals } = match;

        stages[match.stage] = [...(stages[match.stage] ? stages[match.stage] : []), {
            teamA, teamAGoals, vs: "vs", teamBGoals, teamB
        }];
        return stages;
    }, {});
}
const getMockMini = () => {
    return MockMatches.filter(matchMocked => matchMocked.cup === "MINI COPA").reduce((stages, match) => {
        const { teamA, teamB, teamAGoals, teamBGoals } = match;

        stages[match.stage] = [...(stages[match.stage] ? stages[match.stage] : []), {
            teamA, teamAGoals, vs: "vs", teamBGoals, teamB
        }];
        return stages;
    }, {});
}

const MockRanks = [
    {
      "Posición": 1,
      "Equipos": "DEPORTIVO CRISOL",
      "Juegos Jugados": 2,
      "Juegos Ganados": 2,
      "Juegos Empatados": 0,
      "Juegos Perdidos": 0,
      "Goles a favor": 15,
      "Goles en contra": 1,
      "Diferencia de Goles": 14,
      "Puntos": 6
    },
    {
      "Posición": 2,
      "Equipos": "SELECCIÓN PPG",
      "Juegos Jugados": 2,
      "Juegos Ganados": 2,
      "Juegos Empatados": 0,
      "Juegos Perdidos": 0,
      "Goles a favor": 14,
      "Goles en contra": 0,
      "Diferencia de Goles": 14,
      "Puntos": 6
    },
    {
      "Posición": 3,
      "Equipos": "SOMOS GBS",
      "Juegos Jugados": 2,
      "Juegos Ganados": 2,
      "Juegos Empatados": 0,
      "Juegos Perdidos": 0,
      "Goles a favor": 10,
      "Goles en contra": 0,
      "Diferencia de Goles": 10,
      "Puntos": 6
    },
    {
      "Posición": 4,
      "Equipos": "VINIMEX TEPEXPAN",
      "Juegos Jugados": 2,
      "Juegos Ganados": 2,
      "Juegos Empatados": 0,
      "Juegos Perdidos": 0,
      "Goles a favor": 10,
      "Goles en contra": 0,
      "Diferencia de Goles": 10,
      "Puntos": 6
    },
    {
      "Posición": 5,
      "Equipos": "UNIÓN PPG",
      "Juegos Jugados": 2,
      "Juegos Ganados": 2,
      "Juegos Empatados": 0,
      "Juegos Perdidos": 0,
      "Goles a favor": 8,
      "Goles en contra": 1,
      "Diferencia de Goles": 7,
      "Puntos": 6
    },
    {
      "Posición": 6,
      "Equipos": "ONE PPG FC",
      "Juegos Jugados": 2,
      "Juegos Ganados": 2,
      "Juegos Empatados": 0,
      "Juegos Perdidos": 0,
      "Goles a favor": 4,
      "Goles en contra": 0,
      "Diferencia de Goles": 4,
      "Puntos": 6
    },
    {
      "Posición": 7,
      "Equipos": "EMPODERADOS PPG FC",
      "Juegos Jugados": 2,
      "Juegos Ganados": 2,
      "Juegos Empatados": 0,
      "Juegos Perdidos": 0,
      "Goles a favor": 2,
      "Goles en contra": 0,
      "Diferencia de Goles": 2,
      "Puntos": 6
    },
    {
      "Posición": 8,
      "Equipos": "REAL COMEX TEPEXPAN",
      "Juegos Jugados": 2,
      "Juegos Ganados": 1,
      "Juegos Empatados": 2,
      "Juegos Perdidos": 0,
      "Goles a favor": 12,
      "Goles en contra": 2,
      "Diferencia de Goles": 10,
      "Puntos": 5
    },
    {
      "Posición": 9,
      "Equipos": "FC AGA",
      "Juegos Jugados": 2,
      "Juegos Ganados": 1,
      "Juegos Empatados": 2,
      "Juegos Perdidos": 0,
      "Goles a favor": 9,
      "Goles en contra": 2,
      "Diferencia de Goles": 7,
      "Puntos": 5
    },
    {
      "Posición": 10,
      "Equipos": "COMEX FC",
      "Juegos Jugados": 2,
      "Juegos Ganados": 1,
      "Juegos Empatados": 2,
      "Juegos Perdidos": 0,
      "Goles a favor": 6,
      "Goles en contra": 0,
      "Diferencia de Goles": 6,
      "Puntos": 5
    },
    {
      "Posición": 11,
      "Equipos": "REAL CSV (Metokote)",
      "Juegos Jugados": 2,
      "Juegos Ganados": 1,
      "Juegos Empatados": 2,
      "Juegos Perdidos": 0,
      "Goles a favor": 6,
      "Goles en contra": 0,
      "Diferencia de Goles": 6,
      "Puntos": 5
    },
    {
      "Posición": 12,
      "Equipos": "CITIKROM",
      "Juegos Jugados": 2,
      "Juegos Ganados": 1,
      "Juegos Empatados": 2,
      "Juegos Perdidos": 0,
      "Goles a favor": 3,
      "Goles en contra": 2,
      "Diferencia de Goles": 1,
      "Puntos": 5
    },
    {
      "Posición": 13,
      "Equipos": "ATLETICO ALTIVA",
      "Juegos Jugados": 2,
      "Juegos Ganados": 1,
      "Juegos Empatados": 0,
      "Juegos Perdidos": 1,
      "Goles a favor": 9,
      "Goles en contra": 3,
      "Diferencia de Goles": 6,
      "Puntos": 3
    },
    {
      "Posición": 14,
      "Equipos": "ATLÉTICO IMPERTEX",
      "Juegos Jugados": 2,
      "Juegos Ganados": 1,
      "Juegos Empatados": 0,
      "Juegos Perdidos": 1,
      "Goles a favor": 10,
      "Goles en contra": 5,
      "Diferencia de Goles": 5,
      "Puntos": 3
    },
    {
      "Posición": 15,
      "Equipos": "TIBURONES DE MINATITLAN",
      "Juegos Jugados": 2,
      "Juegos Ganados": 1,
      "Juegos Empatados": 0,
      "Juegos Perdidos": 1,
      "Goles a favor": 3,
      "Goles en contra": 2,
      "Diferencia de Goles": 1,
      "Puntos": 3
    },
    {
      "Posición": 16,
      "Equipos": "REAL IZTACALCO",
      "Juegos Jugados": 2,
      "Juegos Ganados": 1,
      "Juegos Empatados": 0,
      "Juegos Perdidos": 1,
      "Goles a favor": 3,
      "Goles en contra": 2,
      "Diferencia de Goles": 1,
      "Puntos": 3
    },
    {
      "Posición": 17,
      "Equipos": "DREAM TEAM KROMA-CEP",
      "Juegos Jugados": 2,
      "Juegos Ganados": 1,
      "Juegos Empatados": 0,
      "Juegos Perdidos": 1,
      "Goles a favor": 4,
      "Goles en contra": 3,
      "Diferencia de Goles": 1,
      "Puntos": 3
    },
    {
      "Posición": 18,
      "Equipos": "COLEGUITAS",
      "Juegos Jugados": 2,
      "Juegos Ganados": 1,
      "Juegos Empatados": 0,
      "Juegos Perdidos": 1,
      "Goles a favor": 6,
      "Goles en contra": 6,
      "Diferencia de Goles": 0,
      "Puntos": 3
    },
    {
      "Posición": 19,
      "Equipos": "REAL CUAUTITLAN",
      "Juegos Jugados": 2,
      "Juegos Ganados": 0,
      "Juegos Empatados": 2,
      "Juegos Perdidos": 1,
      "Goles a favor": 2,
      "Goles en contra": 3,
      "Diferencia de Goles": -1,
      "Puntos": 2
    },
    {
      "Posición": 20,
      "Equipos": "GUANATOS FC",
      "Juegos Jugados": 2,
      "Juegos Ganados": 0,
      "Juegos Empatados": 2,
      "Juegos Perdidos": 1,
      "Goles a favor": 0,
      "Goles en contra": 4,
      "Diferencia de Goles": -4,
      "Puntos": 2
    },
    {
      "Posición": 21,
      "Equipos": "CHIWAS TEAM",
      "Juegos Jugados": 2,
      "Juegos Ganados": 0,
      "Juegos Empatados": 2,
      "Juegos Perdidos": 1,
      "Goles a favor": 0,
      "Goles en contra": 6,
      "Diferencia de Goles": -6,
      "Puntos": 2
    },
    {
      "Posición": 22,
      "Equipos": "INYECTORES PPG",
      "Juegos Jugados": 2,
      "Juegos Ganados": 0,
      "Juegos Empatados": 0,
      "Juegos Perdidos": 2,
      "Goles a favor": 17,
      "Goles en contra": 2,
      "Diferencia de Goles": 15,
      "Puntos": 0
    },
    {
      "Posición": 23,
      "Equipos": "HALCONES TICOMAN",
      "Juegos Jugados": 2,
      "Juegos Ganados": 0,
      "Juegos Empatados": 0,
      "Juegos Perdidos": 2,
      "Goles a favor": 1,
      "Goles en contra": 4,
      "Diferencia de Goles": -3,
      "Puntos": 0
    },
    {
      "Posición": 24,
      "Equipos": "HERMOSILLO FC",
      "Juegos Jugados": 2,
      "Juegos Ganados": 0,
      "Juegos Empatados": 0,
      "Juegos Perdidos": 2,
      "Goles a favor": 0,
      "Goles en contra": 5,
      "Diferencia de Goles": -5,
      "Puntos": 0
    },
    {
      "Posición": 25,
      "Equipos": "PP GOLDEN",
      "Juegos Jugados": 2,
      "Juegos Ganados": 0,
      "Juegos Empatados": 0,
      "Juegos Perdidos": 2,
      "Goles a favor": 1,
      "Goles en contra": 9,
      "Diferencia de Goles": -8,
      "Puntos": 0
    },
    {
      "Posición": 26,
      "Equipos": "CIMARRONES TJ",
      "Juegos Jugados": 2,
      "Juegos Ganados": 0,
      "Juegos Empatados": 0,
      "Juegos Perdidos": 2,
      "Goles a favor": 0,
      "Goles en contra": 12,
      "Diferencia de Goles": -12,
      "Puntos": 0
    },
    {
      "Posición": 27,
      "Equipos": "ATLETICO PPG TULTITLÁN",
      "Juegos Jugados": 2,
      "Juegos Ganados": 0,
      "Juegos Empatados": 0,
      "Juegos Perdidos": 2,
      "Goles a favor": 2,
      "Goles en contra": 14,
      "Diferencia de Goles": -12,
      "Puntos": 0
    },
    {
      "Posición": 28,
      "Equipos": "FC IZTACALCO",
      "Juegos Jugados": 2,
      "Juegos Ganados": 0,
      "Juegos Empatados": 0,
      "Juegos Perdidos": 2,
      "Goles a favor": 0,
      "Goles en contra": 17,
      "Diferencia de Goles": -17,
      "Puntos": 0
    },
    {
      "Posición": 29,
      "Equipos": "LEONES",
      "Juegos Jugados": 2,
      "Juegos Ganados": 0,
      "Juegos Empatados": 0,
      "Juegos Perdidos": 2,
      "Goles a favor": 0,
      "Goles en contra": 20,
      "Diferencia de Goles": -20,
      "Puntos": 0
    },
    {
      "Posición": 30,
      "Equipos": "AP RESINAS",
      "Juegos Jugados": 0,
      "Juegos Ganados": 0,
      "Juegos Empatados": 0,
      "Juegos Perdidos": 0,
      "Goles a favor": 0,
      "Goles en contra": 0,
      "Diferencia de Goles": 0,
      "Puntos": 0
    }
  ]

const stages = {
    main: [
        {
            title: "Primera ronda",
            matches: 30,
            teams: 30,
            fields: [1,2,3,4,5,6,7,8,9,10],
            startTime: new Date("2024-09-22T15:30:00.000Z"),
            endTime: new Date("2024-09-22T17:30:00.000Z"),
        },
        {
            title: "Segunda ronda",
            matches: 8,
            teams: 16,
            fields: [1,2,3,4,5,6,7,8],
            startTime: new Date("2024-09-22T17:40:00.000Z"),
            endTime: new Date("2024-09-22T18:20:00.000Z"),
        },
        {
            title: "Cuartos de final",
            matches: 4,
            teams: 8,
            fields: [1,3,4,6],
            startTime: new Date("2024-09-22T20:00:00.000Z"),
            endTime: new Date("2024-09-22T20:40:00.000Z"),
        },
        {
            title: "Semifinales",
            matches: 2,
            teams: 4,
            fields: [4,6],
            startTime: new Date("2024-09-22T20:40:00.000Z"),
            endTime: new Date("2024-09-22T21:20:00.000Z"),
        },
        {
            title: "Final",
            matches: 1,
            teams: 2,
            fields: [6],
            startTime: new Date("2024-09-22T21:20:00.000Z"),
            endTime: new Date("2024-09-22T22:00:00.000Z"),
        },
        {
            title: "Tercer Lugar",
            matches: 1,
            teams: 2,
            fields: [4],
            startTime: new Date("2024-09-22T21:20:00.000Z"),
            endTime: new Date("2024-09-22T22:00:00.000Z"),
        },
    ],
    retake: [
        {
            title: "Primera ronda",
            matches: 7,
            teams: 14,
            fields: [1,2,3,4,5,6,7],
            startTime: new Date("2024-09-22T18:30:00.000Z"),
            Time: new Date("2024-09-22T18:30:00.000Z"),
            endTime: new Date("2024-09-22T19:10:00.000Z"),
        },
        {
            title: "Semifinales",
            matches: 2,
            teams: 4,
            fields: [1,3],
            startTime: new Date("2024-09-22T19:20:00.000Z"),
            endTime: new Date("2024-09-22T20:00:00.000Z"),
        },
        {
            title: "Final",
            matches: 1,
            teams: 2,
            fields: [3],
            startTime: new Date("2024-09-22T21:20:00.000Z"),
            endTime: new Date("2024-09-22T22:00:00.000Z"),
        },
        {
            title: "Tercer Lugar",
            matches: 1,
            teams: 2,
            fields: [1],
            startTime: new Date("2024-09-22T21:20:00.000Z"),
            endTime: new Date("2024-09-22T22:00:00.000Z"),
        },
    ]
};

const getTime = (config) => {
    let date_now = config.pauseTime ? new Date(config.pauseTime) : new Date();
    let date_future = config.startTime ? new Date(config.startTime) : new Date();
    date_future.setMinutes(date_future.getMinutes() + 40);

    // get total seconds between the times
    let delta = Math.abs(date_future - date_now) / 1000;

    // calculate (and subtract) whole days
    let days = Math.floor(delta / 86400);
    delta -= days * 86400;

    // calculate (and subtract) whole hours
    let hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    // calculate (and subtract) whole minutes
    let minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    // what's left is seconds
    let seconds = delta % 60;  // in theory the modulus is not required

    return { days, hours, minutes, seconds }
}

export const Matches = () => {
    const [isMobile] = useState(!window.matchMedia("(min-width: 768px)").matches);
    const [watching, setWatching] = useState();
    const [activeTab, setActiveTab] = useState("matches");
    const [matches, setMatches] = useState([]);
    const { authorization, role } = useSession();
    const sportiService = new SportiService(authorization);
    const [currentMatch, setMatch] = useState(); 
    const [incidence, setIncidence] = useState("");
    const [matchRemaining, setMatchRemaining] = useState(getTime({}));
    const swal = withReactContent(Swal);
    const [ranks, setRanks] = useState([]);
    const [watchingMatch, setWatchingMatch] = useState(false);
    const [schedules, setSchedules] = useState([]);
    const [teamIdW, setTeamIDW] = useState("default");
    const [newSchedule, setNewSchedule] = useState({ teamId: teamIdW});
    const [viewField, setViewField] = useState();

    const incidences = [
        {label: "Falta", icon: faX},
        {label: "Tarjeta amarilla", icon: faTicket},
        {label: "Tarjeta roja", icon: faTicket},
        {label: "Penalti ", icon: faFutbol},
        {label: "Tiro de castigo", icon: faFutbol},
        {label: "Gol en propia portería", icon: faFutbol},
        {label: "Jugador sale", icon: faMinusCircle},
        {label: "Jugadore entra", icon: faPlusCircle},
        {label: "Inicio primera parte", icon: faPlay},
        {label: "Inicio segunda parte", icon: faPlay},
        {label: "Finaliza partido", icon: faClock},
        {label: "Acta cerrada", icon: faClipboardCheck},
        {label: "Tanda de penaltis", icon: faFutbol},
    ]



    const startMatch = () => {
        setMatch({ ...currentMatch, startTime: new Date().toISOString(), matchDurationInMinutes: currentMatch.matchDurationInMinutes })
    }
    
    const sendSchedule = () => {
        sportiService.setSchedules(newSchedule, teamIdW)
        setSchedules([...schedules, {...newSchedule, TeamId: teamIdW}]);
        setNewSchedule({ teamId: teamIdW, description:"", time:""});

    }

    const getStage = (cup) => {
        return matches.filter(match =>  match.cup === cup).sort((a, b) => b.stage - a.stage)[0]?.stage
    }

    const isFinished = (cup) => {
        return Boolean(matches.filter(
            match => (
                match.cup === cup 
                && match.stage === stages[cup].findIndex(stageInfo => stageInfo.title ===  "Final")
                && match.status === "FINALIZADO"
            )
        ).length) 
        && Boolean(matches.filter(
            match => (
                match.cup === cup 
                && match.stage === stages[cup].findIndex(stageInfo => stageInfo.title ===  "Tercer Lugar")
                && match.status === "FINALIZADO"
            )
        ).length);
    }

    const getWinners = (cup) => {
        let firstPlace = {teamName:"first Place"};
        let secondPlace = {teamName:"second Place"};
        let thirdPlace = {teamName:"third Place"};

        let final = matches.filter(
            match => (
                match.cup === cup 
                && match.stage === stages[cup].findIndex(stageInfo => stageInfo.title ===  "Final")
            )
        )[0];
        
        let thirdPlaceMatch = matches.filter(
            match => (
                match.cup === cup 
                && match.stage === stages[cup].findIndex(stageInfo => stageInfo.title ===  "Tercer Lugar")
            )
        )[0];

        if(final){
            if(final.teamA.goals > final.teamB.goals) {
                firstPlace = final.teamA;
                secondPlace = final.teamB;
            }
            else {
                firstPlace = final.teamB;
                secondPlace = final.teamA;
            }
        }

        if(thirdPlaceMatch){
            if(thirdPlaceMatch.teamA.goals > thirdPlaceMatch.teamB.goals) {
                thirdPlace = thirdPlaceMatch.teamA;
            }
            else {
                thirdPlace = thirdPlaceMatch.teamB;
            }
        }
        

        return [firstPlace, secondPlace, thirdPlace].map(team =>
            <li>{team.teamName}</li>
        );
    }

    const generateMatches = (cup) => {
        swal.fire({
            didOpen: () => swal.showLoading()
        });

        let newStage = matches.filter(match =>  match.cup === cup).sort((a, b) => b.stage - a.stage)[0].stage + 1;

        sportiService.generateMatches({ stage: newStage, cup })
        .then(() => {
            sportiService.getMatches()
            .then(newMatches => {
                setMatches(newMatches);
                swal.close();
            });
        })
    }

    const resumeMatch = () => {
        const stoppedMinutes = Math.floor((Math.abs(new Date() - new Date(currentMatch.pauseTime))/1000)/60);

        setMatch({
            ...currentMatch,
            pauseTime: undefined,
            matchDurationInMinutes: currentMatch.matchDurationInMinutes + stoppedMinutes
        })
    }
    
    const endMatch = () => {
        swal.fire({
            title: "¿Desea finalizar el partido?",
            text: "Esto enviará los resultados a las tablas instantaneamente",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si, finalizar",
            cancelButtonText: "No, volver",
        })
        .then(result => {
            if(result.isConfirmed){
                swal.close();
                swal.fire({
                    didOpen: () => swal.showLoading()
                });
                Promise.all([
                    sportiService.setMatches(currentMatch),
                    sportiService.rateMatch(currentMatch),
                    sportiService.getMatches()
                ])
                .then((results) => {
                    setMatches([]);

                    return sportiService.setMatches({
                        ...currentMatch,
                        status: "FINALIZADO"
                    })
                })
                .then(() => {
                    setMatch(undefined);
                    swal.close()
                });
            }
        });

    }

    useEffect(() => {
        if(!matches.length){
            sportiService.getMatches()
            .then(matches => {
                setMatches(matches);
            })
            if(!schedules.length){
                sportiService.getSchedules()
                .then(freshSchedules => setSchedules(freshSchedules));
            }
        }


        if(currentMatch && currentMatch.startTime){
            const timer = (matchRemaining.seconds + matchRemaining.minutes + matchRemaining.hours + matchRemaining.days) > 0 
                && setInterval(() => setMatchRemaining(getTime({
                    startTime: currentMatch.startTime,
                    pauseTime: currentMatch.pauseTime,
                    matchDurationInMinutes: currentMatch.matchDurationInMinutes,
                })), 1000);
            return () => clearInterval(timer);
        }


    },[matchRemaining, currentMatch?.startTime]);

    useEffect(() => {
        if(!matches.length){
            sportiService.getMatches()
            .then(matches => {
                setMatches(matches);
            })
        }

        if(currentMatch && currentMatch.startTime){
            const timer = (matchRemaining.seconds + matchRemaining.minutes + matchRemaining.hours + matchRemaining.days) > 0 
                && setInterval(() => setMatchRemaining(getTime({
                    startTime: currentMatch.startTime,
                    pauseTime: currentMatch.pauseTime,
                    matchDurationInMinutes: currentMatch.matchDurationInMinutes,
                })), 1000);
            return () => clearInterval(timer);
        }


    },[matchRemaining, currentMatch?.startTime]);

    useEffect(() => {
        if((!role.includes(Roles.REFEREE)) && currentMatch && !watchingMatch) {
            setInterval(() => {
                sportiService.getMatches()
                .then(refreshedMatches => {
                    setWatchingMatch(true);
                    setMatch(
                        refreshedMatches.find(refreshedMatch => refreshedMatch.id === currentMatch.id)
                    );
                })
            }, 5000)
        }
    });

    useEffect(() => {
        if(role.includes(Roles.REFEREE)){
            sportiService.setMatches(currentMatch);
        }
    }, [currentMatch])

    useEffect(() => {
        if(!ranks.length && matches.length){
            sportiService.getTeams()
            .then(teams => {
                let newArray = [];
                if(getStage("main") === 0){
                    newArray = [];
                    let newRanks = teams.reduce((groups, team) => {
                        let field = matches.find(match => match.teamA.teamName == team.teamName)?.field;
                        if(!field) field = matches.find(match => match.teamB.teamName == team.teamName)?.field;
                        team.field = field;
                        groups[field] = [...(groups[team.field] || []), team].sort((a, b) => (b.points - a.points)).map((team, position) => ({...team, position: position + 1 }));
                        return groups;
                    }, {})
                    
                    let rks= Object.keys(newRanks).map(
                        group => 
                            (newRanks[group].map(
                                team => newArray = [...newArray, team]    
                            )
                        )
                    )
                } else {
                    newArray=[]
                    newArray = teams.sort((a, b) => {
                        // Puntos
                        if(a.points !== b.points){
                            return b.points - a.points;
                        }
                        // Goles
                        if(a.goals !== b.goals){
                            return b.points - a.points;
                        }
                        // Diferencia 
                        return (a.goals - a.consGoals) - (a.goals - a.consGoals)
                    });
                }

                setRanks(newArray)
            });
        }
    }, [matches])

    const food = (<>
        <div class="select is-link">
            <select
                onChange={e => {
                    setWatching(e.target.value);
                    setTeamIDW(teams.find(team => team.teamName === e.target.value)?.id)
                }}
            >
                <option>Selecciona a tu equipo</option>
                {teams.map(team => 
                    <option value={team.teamName}>{team.teamName}</option>
                )}
            </select>
        </div>

        <Card
            cssClasses='mt-3'
            content={<div className='columns'>
                {watching && <div className='column is-one-quarter'>
                    <Title 
                        main={{
                            text: watching,
                            type: "subtitle"
                        }
                    }/>
                    <Image src={`/assets/teams/${watching}.png`}
                        style={{
                            height:"auto",
                            maxHeight: "10rem",
                            width:"auto",
                            margin:"auto",
                        }}
                    />
                </div>}
                <Table
                    cssClasses=''
                    body={[
                        { 
                            isSelected: false,
                            listOfCells: [
                                //{ content: watching },
                                { content: 'Comida' },
                                { content: 'Consúltalo al terminar cada partido' },
                                {}
                            ]
                        },
                        { 
                            isSelected: false,
                            listOfCells: [
                                //{ content: '' },
                                { content: 'Actividad de banderas' },
                                { content: 'Consúltalo al terminar cada partido' },
                                {}
                            ]
                        },
                        { 
                            isSelected: false,
                            listOfCells: [
                                //{ content: '' },
                                { content: 'Conexión Hamable' },
                                { content: 'Consúltalo al terminar cada partido' },
                                {}
                            ]
                        },
                        ...schedules.filter(sch => sch.TeamId === teamIdW).map(fschedule => ({
                            isSelected: false,
                            listOfCells: [
                                //{ content: '' },
                                { content: fschedule.description },
                                { content: fschedule.time },
                                {}
                            ]
                        })),
                    ]}
                    head={[
                        //{ content: 'Equipo' },
                        { content: 'Actividad' },
                        { content: 'Horario' },
                        { content: '' },
                    ]}
                />
            </div>}
            headerText="Comida y otras actividades"
        />
       {teamIdW && role.includes(Roles.ORGANIZER) &&<div className='columns mt-5'>
                <div className='column'><input placeholder="Actividad" key="i1" value={newSchedule.description} className="input" type="text" onChange={e => setNewSchedule({...newSchedule, description: e.target.value})}/></div>
                <div className='column'><input key="i2" placeholder="Horario" value={newSchedule.time} className="input" type="text" onChange={e => setNewSchedule({...newSchedule, time: e.target.value})}/></div>
                <div className='column'><button onClick={() => sendSchedule()} className='button is-info is-outlined m-2'>GUARDAR &nbsp;<FontAwesomeIcon icon={faSave}/></button></div>
        </div>}
    </>);

    const tables = (<>
        <Card
            cssClasses='mt-3'
            content={<div className='columns'>
                {watching && <div className='column is-one-quarter'>
                    <Title 
                        main={{
                            text: watching,
                            type: "subtitle"
                        }
                    }/>
                    <Image src={`/assets/teams/${watching}.png`}
                        style={{
                            height:"auto",
                            maxHeight: "10rem",
                            width:"auto",
                            margin:"auto",
                        }}
                    />
                </div>}
                <Table
                    cssClasses=''
                    body={
                        (() => {
                            return MockRanks.map(team => ({
                                    isSelected: false,
                                    listOfCells: Object.keys(team).map(header => ({ content: team[header] }))
                            }))
                        })()
                    }
                    head={Object.keys(MockRanks[0]).map(header => ({ content: header }))}
                />
            </div>}
            headerText="Tabla de equipos"
        />
    </>);

    if(currentMatch) return (<>
        <div className="has-text-dark" style={{
            position: "absolute",
            width:"100%",
            top: isMobile ? "11rem":"8rem",
            left:"0",
            color: "#000",
            textAlign:"left"
        }}>
            <Card
                content={<>
                    <center>
                        <Title
                            main={{
                                text:`${String(matchRemaining.minutes).padStart(2, "0")}:${String(matchRemaining.seconds.toFixed()).padStart(2, "0")}`,
                                type: "title"
                            }}
                        />
                    </center>
                    <table className="has-text-centered" style={{ margin: "auto" }}>
                        <tr>
                            <td style={{minWidth:"8rem", border:"0px"}}>
                                <Image src={`/assets/teams/${currentMatch.teamA.teamName}.png`}
                                    style={{
                                        height:"auto",
                                        maxHeight: "20vh",
                                        width:"auto",
                                        margin:"auto",
                                    }}
                                />
                                <Title 
                                    main={{
                                        text: <span style={{textOverflow:"clip", fontSize:"0.8rem" }}>{currentMatch.teamA.teamName}</span>,
                                        type: "subtitle"
                                    }}
                                />
                            </td>
                            <td style={{minWidth:"4rem", border:"0px"}}>
                                <Title
                                    main={{
                                        text:<>&nbsp;</>,
                                        type: "title"
                                    }}
                                    secondary={{
                                        text: "VS",
                                        type: "subtitle"
                                    }}
                                />
                            </td>
                            <td style={{minWidth:"8rem", border:"0px"}}>
                                <Image src={`/assets/teams/${currentMatch.teamB.teamName}.png`}
                                    style={{
                                        height:"auto",
                                        maxHeight: "20vh",
                                        width:"auto",
                                        margin:"auto",
                                    }}
                                />
                                <Title 
                                    main={{
                                        text: <span style={{textOverflow:"clip", fontSize:"0.8rem" }}>{currentMatch.teamB.teamName}</span>,
                                        type: "subtitle"
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td style={{minWidth:"8rem", border:"0px"}}>
                                {role.includes(Roles.REFEREE) && <button
                                    style={{ marginLeft:"-1rem", fontWeight:"bold", width:"1rem", border: "0px", background:"#0000", lineHeight: "0rem", fontSize:"1.3rem"}}
                                    className='has-text-success mr-5'
                                    onClick={() => setMatch({ ...currentMatch, teamA: { ...currentMatch.teamA, goals: Number(currentMatch.teamA.goals + 1)} })}
                                >
                                    <FontAwesomeIcon icon={faPlusCircle} />&nbsp;
                                </button>}
                                <span 
                                    style={{
                                        border: "0px",
                                        borderBottom: "0px solid #777",
                                        textAlign: "center",
                                        fontSize: "2.5rem",
                                        width: "6rem"
                                    }}
                                    type="number"
                                >{currentMatch.teamA.goals}</span>
                                {role.includes(Roles.REFEREE) && <button
                                    style={{ fontWeight:"bold", width:"1rem", border: "0px", background:"#0000", lineHeight: "0rem", fontSize:"1.3rem"}}
                                    className='has-text-danger ml-1'
                                    onClick={() => setMatch({ ...currentMatch, teamA: { ...currentMatch.teamA, goals: currentMatch.teamA.goals > 0 ? Number(currentMatch.teamA.goals - 1) : 0} })}
                                >
                                    <FontAwesomeIcon icon={faMinusCircle} />&nbsp;
                                </button>}
                            </td>
                            <td style={{minWidth:"4rem", border:"0px"}}></td>
                            <td style={{minWidth:"8rem", border:"0px"}}>
                                {role.includes(Roles.REFEREE) && <button
                                    style={{ marginRight:"1rem", fontWeight:"bold", width:"1rem", border: "0px", background:"#0000", lineHeight: "0rem", fontSize:"1.3rem"}}
                                    className='has-text-danger'
                                    onClick={() => setMatch({ ...currentMatch, teamB: { ...currentMatch.teamB, goals: currentMatch.teamB.goals > 0 ? Number(currentMatch.teamB.goals - 1) : 0} })}
                                >
                                    <FontAwesomeIcon icon={faMinusCircle} />&nbsp;
                                </button>}
                                <span 
                                    style={{
                                        border: "0px",
                                        borderBottom: "0px solid #777",
                                        textAlign: "center",
                                        fontSize: "2.5rem",
                                        width: "6rem"
                                    }}
                                    type="number"
                                >{currentMatch.teamB.goals}</span>
                                {role.includes(Roles.REFEREE) && <button
                                    style={{ fontWeight:"bold", width:"1rem", border: "0px", background:"#0000", lineHeight: "0rem", fontSize:"1.3rem"}}
                                    className='has-text-success ml-1'
                                    onClick={() => setMatch({ ...currentMatch, teamB: { ...currentMatch.teamB, goals: Number(currentMatch.teamB.goals + 1)} })}
                                >
                                    <FontAwesomeIcon icon={faPlusCircle} />&nbsp;
                                </button>}
                            </td>
                        </tr>
                    </table>
                </>}
            />
            {(role.includes(Roles.REFEREE)) && <div className='columns has-text-centered mt-5'>
                
                <div className='column'>
                    {currentMatch.startTime && 
                        <>
                            {!currentMatch.pauseTime && <button
                                style={{ fontWeight:"bold", width:"90%"}}
                                className='button is-info is-outlined m-2'
                                onClick={() => setMatch({...currentMatch, pauseTime: new Date().toISOString()})}
                            >
                                <FontAwesomeIcon icon={faPause} />&nbsp;
                                PAUSAR PARTIDO
                            </button>}

                            {currentMatch.pauseTime && <button
                                style={{ fontWeight:"bold", width:"90%"}}
                                className='button is-info is-outlined m-2'
                                onClick={() => resumeMatch()}
                            >
                                <FontAwesomeIcon icon={faPlay} />&nbsp;
                                REANUDAR PARTIDO
                            </button>}
                        </>
                    }
                    <br></br>
                {currentMatch.startTime ? <>
                    <button
                        style={{ fontWeight:"bold", width:"90%"}}
                        className='button is-info is-outlined m-2'
                        onClick={() => endMatch()}
                    >
                        
                        <FontAwesomeIcon icon={faClock} />&nbsp;
                        FINALIZAR PARTIDO
                    </button>
                </> : <>
                    <button
                        style={{ fontWeight:"bold", width:"90%"}}
                        className='button is-info is-outlined m-2'
                        onClick={() => startMatch()}
                    >
                        
                        <FontAwesomeIcon icon={faPlay} />&nbsp;
                        INICIAR PARTIDO
                    </button>
                </>}
                    
                    
                </div>

                <div className='column'>
                    <input className='button' list="cityname" value={incidence} type="text" style={{width:"75%", padding:"0 5%"}} onChange={(e) => setIncidence(e.target.value)}/>
                    <datalist id="cityname">
                        {incidences.map(incidenceItem => <option value={incidenceItem.label} />)}
                    </datalist>

                    <button
                        style={{ fontWeight:"bold", width:"15%"}}
                        className='button is-info is-outlined'
                        onClick={() => {setMatch({...currentMatch, incidence: [...currentMatch.incidence || [], incidence]}); setIncidence("")}}
                    >
                        <FontAwesomeIcon icon={faCheckCircle} />&nbsp;
                    </button>
                    <ul className='mt-2'>
                        {(currentMatch.incidence ? currentMatch.incidence:[]).map(i => <li>
                            <FontAwesomeIcon icon={incidences.find(item => item.label === i) ? incidences.find(item => item.label === i).icon : faClipboard} />&nbsp;{i}
                        </li>)}
                    </ul>
                </div>

                
                
                <div className='column'></div>
            </div>}
        </div>
    </>);

    if(!currentMatch) return(<div className="has-text-dark" style={{
        position: "absolute",
        width:"100%",
        top: isMobile ? "11rem":"8rem",
        left:"0",
        color: "#000",
        textAlign:"left"
    }}>
        <div className='container'>
            <Title
                main={{
                    text: 'Partidos',
                    type: 'title'
                }}
            />
            
            <div class="tabs">
                <ul>
                    <li
                        className={activeTab === "matches" && 'is-active'} 
                        style={{ borderBottom: "1px solid", color: activeTab === "matches" ? "#4e73bb" : "#4a4a4a" }}
                        onClick={() => setActiveTab('matches')}
                    >
                        <span style={tabStyle}>Partidos anunciados</span>
                    </li>
                    
                    <li
                        className={activeTab === "schedule" && 'is-active'} 
                        style={{ borderBottom: "1px solid", color: activeTab === "schedule" ? "#4e73bb" : "#4a4a4a" }}
                        onClick={() => setActiveTab('schedule')}
                    >
                        <span style={tabStyle}>Horarios</span>
                    </li>

                    <li
                        className={activeTab === "food" && 'is-active'} 
                        style={{ borderBottom: "1px solid", color: activeTab === "food" ? "#4e73bb" : "#4a4a4a" }}
                        onClick={() => setActiveTab('food')}
                    >
                        <span style={tabStyle}>Comida y otras actividades</span>
                    </li>

                    <li
                        className={activeTab === "tables" && 'is-active'} 
                        style={{ borderBottom: "1px solid", color: activeTab === "tables" ? "#4e73bb" : "#4a4a4a" }}
                        onClick={() => setActiveTab('tables')}
                    >
                        <span style={tabStyle}>Tablas</span>
                    </li>
                </ul>
            </div>

            {activeTab === "matches" && <div className='mt-5'>
                <div class="select is-link mb-5 ml-5">
                    <select
                        onChange={e => {
                            setViewField(e.target.value);
                        }}
                    >
                        <option value={null}>Selecciona una cancha para ver sus partidos</option>
                        {[1,2,3,4,5,6,7,8,9,10].map(F => 
                            <option value={F}>Cancha {F}</option>
                        )}
                    </select>
                </div>
                {
                    Boolean(matches.length)
                    && matches.filter(match => match.status !== "FINALIZADO" && match.cup === "main").length === 0
                    && (role.includes(Roles.ORGANIZER) || role.includes(Roles.REFEREE))
                    && getStage("main") < 4
                    && <div column>
                    <button
                                style={{ fontWeight:"bold", width:"90%"}}
                                className='button is-info is-outlined ml-5 mb-2'
                                onClick={() => {
                                    generateMatches("main")
                                }}
                            >
                                <FontAwesomeIcon icon={faFutbol} />&nbsp;
                                GENERAR PARTIDOS PARA LA SIGUIENTE RONDA DEL TORNEO
                            </button>
                </div>}
                {
                    Boolean(matches.length)
                    && matches.filter(match => match.status !== "FINALIZADO" && match.cup === "retake").length === 0
                    && matches.filter(match => match.cup === 'retake').length >= 1
                    && (role.includes(Roles.ORGANIZER) || role.includes(Roles.REFEREE))
                    && getStage("retake") < 2
                    && <div column>
                    <button
                                style={{ fontWeight:"bold", width:"90%"}}
                                className='button is-primary is-outlined ml-5'
                                onClick={() => {
                                    generateMatches("retake")
                                }}
                            >
                                <FontAwesomeIcon icon={faFutbol} />&nbsp;
                                GENERAR PARTIDOS PARA LA SIGUIENTE RONDA DE MINICOPA
                            </button>
                </div>}

                {isFinished("main") && 
                <Hero
                    body={
                        <section className="container has-text-light">
                          <Title
                            main={{
                                text: "Felicidades a los ganadores del Torneo 🎉",
                                type: "title"
                            }}
                            secondary={{
                                text: getWinners("main"),
                                type: "subtitle"
                            }}
                          />
                        </section>}
                      size="is-fullwidth"
                      color='is-primary'
                />}
                <br/>
                {isFinished("retake") && 
                <Hero
                    body={
                        <section className="container has-text-light">
                          <Title
                            main={{
                                text: "Felicidades a los ganadores de la Minicopa🏅",
                                type: "title"
                            }}
                            secondary={{
                                text: getWinners("retake"),
                                type: "subtitle"
                            }}
                          />
                        </section>}
                      size="is-fullwidth"
                      color='is-info'
                />}

                <div className='columns is-multiline mt-5'>
                    <div className='column is-half'>
                        {Object.keys(getMockCup()).map(stageID => <Card
                            cssClasses='mb-5'
                            headerText={`${stageID} COPA`}
                            content={
                                <Table
                                    body={
                                        (() => {
                                            return getMockCup()[stageID].map(matchMocked => ({
                                                    isSelected: false,
                                                    listOfCells: Object.keys(matchMocked).map(header => ({ content: matchMocked[header] }))
                                            }))
                                        })()
                                    }
                                    head={[]}
                                />
                            }
                        />)}
                    </div>
                    <div className='column is-half'>
                        {Object.keys(getMockMini()).map(stageID => <Card
                            cssClasses='mb-5'
                            headerText={`${stageID} MINI COPA`}
                            content={
                                <Table
                                    body={
                                        (() => {
                                            return getMockMini()[stageID].map(matchMocked => ({
                                                    isSelected: false,
                                                    listOfCells: Object.keys(matchMocked).map(header => ({ content: matchMocked[header] }))
                                            }))
                                        })()
                                    }
                                    head={[]}
                                />
                            }
                        />)}
                    </div>
                </div>

                <div className='columns is-multiline mt-5'>
                {matches
                    .filter(match => match.status !== "FINALIZADO")
                    .filter(match => {
                        if(!viewField || viewField === "Selecciona una cancha para ver sus partidos"){ return true }
                        console.log(match.field, viewField)
                        return String(match.field) === String(viewField)
                    })
                    .map(match => (
                    <div 
                        className='column is-one-third'
                        onClick={() => {
                            if(true){
                                setMatch(match);
                                console.log("I whora")
                            }
                        }}
                        style={{display: "inline"}}
                    >
                        <Card
                            style={{ cursor:"pointer" }}
                            cssClasses="has-text-dark"
                            headerText={<span>{new Date(match.date).toLocaleString()} <span class="tag is-light is-success mb-1">{match.cup === "main" ? "Torneo" : "Minicopa"}</span>  <span class="tag is-light is-warning mb-1">{`${stages[match.cup][match.stage].title}`}</span> <br/> <span class="tag is-light is-info">{match.teamA.teamName}</span> vs <span class="tag is-light is-info">{match.teamB.teamName}</span></span>}
                            content={(<>
                                <b>Lugar:</b> Cancha {match.field}
                                <table className='has-text-centered'>
                                    <tr>
                                        <td style={{ border: "0px" }}>
                                            <Image src={`/assets/teams/${match.teamA.teamName}.png`}
                                                style={{
                                                    height:"auto",
                                                    maxHeight: "7rem",
                                                    width:"auto",
                                                    margin:"auto",
                                                }}
                                            />
                                        </td>
                                        <td style={{ border: "0px" }}>
                                            <Image src={`/assets/teams/${match.teamB.teamName}.png`}
                                                style={{
                                                    height:"auto",
                                                    maxHeight: "7rem",
                                                    width:"auto",
                                                    margin:"auto",
                                                }}
                                            />
                                        </td>
                                    </tr>
                                </table>
                                {/* <Image src={`/assets/shirts/${match.teamName}.jpeg`}
                                    style={{
                                        maxHeight: "80vh",
                                        width:"auto",
                                        margin:"auto",
                                    }}
                                /> */}
                            </>)}
                        />
                    </div>
                ))}</div>
            </div>}

            {activeTab === "schedule" && <div className=''>
                {intersite} <br/>
                {minicopa}
            </div>
            }
            
            {activeTab === "food" && <div className=''>
                {food}
            </div>}
            
            {activeTab === "tables" && <div className=''>
                {tables}
            </div>}
        </div>
    </div>);
}