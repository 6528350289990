import { Title, Card, Image, Table } from 'reactive-bulma';
import teams from './teams.json';
import players from './players.json';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faImage, faMap, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import { tabStyle } from './matches';
import { Gallery } from '../../../components/gallery';
import { useSession } from '../../../lib/session.helper';
import { Roles } from '../../../etc/roles';
import { SportiService } from './providers/service';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export const GalleryView = () => {
    const [isMobile] = useState(!window.matchMedia("(min-width: 768px)").matches);
    const [activeTab, setActiveTab] = useState("gallery");
    const [files, setFiles] = useState([]);
    const { authorization, role } = useSession();
    const sportiService = new SportiService(authorization);
    const [galleryContent, setGalleryContent] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const swal = withReactContent(Swal);

    const preventDefaults = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const processFiles = (e, files) => {
        e.preventDefault();

        setFiles(files);
    }

    const fileListToArray = fileList => {
        const fileArray = [];

        for (const file of files) {
            fileArray.push(file)
        }

        return fileArray;
    }

    function readFile(file){
        return new Promise((resolve, reject) => {
          var fr = new FileReader();  
          fr.onloadend = () => {
            resolve(fr.result )
          };
          fr.onerror = reject;
          fr.readAsDataURL(file);
        });
      }

    const uploadFiles = () => {
        const photos = fileListToArray(files);
        setIsLoading(true);
        
        swal.fire({
            didOpen: () => swal.showLoading()
        });

        Promise.all(photos.map(
            (photo) => readFile(photo)
        ))
        .then(blobFiles => sportiService
            .postImages(blobFiles)
            .then(mediaLinks => 
                setGalleryContent([...mediaLinks, ...galleryContent])
            )
        )
        .then(() => {
            setFiles([]);
            setActiveTab('gallery')
            setIsLoading(false);
            swal.fire({
                title: "Carga exitosa",
                text: "Las imagenes se han cargado exitosamente",
                icon: "success"
            });
        })
    }

    useEffect(() => {
        if(!galleryContent.length){
            sportiService.getImages()
            .then(images => setGalleryContent(images))
        }
    },[]);

    return(<div className="has-text-dark" style={{
        position: "absolute",
        width:"100%",
        top: isMobile ? "11rem":"8rem",
        left:"0",
        color: "#000",
        textAlign:"left"
    }}>
        <div className='container'>
            <Title
                main={{
                    text: "Revive los mejores momentos del evento.",
                    type: "title"
                }}
            />

            <div class="tabs">
                <ul style={{ borderBottom: "1px solid #ddd" }}>
                    <li
                        className={activeTab === "gallery" && 'is-active'} 
                        style={{ color: activeTab === "gallery" ? "#3e8ed0" : "#4a4a4a" }}
                        onClick={() => setActiveTab('gallery')}
                    >
                        <span style={activeTab === "gallery" ? {...tabStyle, borderBottom:"1px solid #3e8ed0" } : tabStyle}>
                            <FontAwesomeIcon style={{ fontSize:"1.3rem", margin:"0 1rem" }} icon={faImage} />    
                            Galería
                        </span>
                    </li>

                    {role.includes(Roles.PHOTOGRAPHER) && <li
                        className={activeTab === "upload" && 'is-active'} 
                        style={{ color: activeTab === "upload" ? "#3e8ed0" : "#4a4a4a" }}
                        onClick={() => setActiveTab('upload')}
                    >
                        <span style={activeTab === "upload" ? {...tabStyle, borderBottom:"1px solid #3e8ed0" } : tabStyle}>
                            <FontAwesomeIcon style={{ fontSize:"1.3rem", margin:"0 1rem" }} icon={faUpload} />
                            Cargar Imagen
                        </span>
                    </li>}
                </ul>
            </div>

            {activeTab === "upload" && 
            <div 
                // style={{ color: "#fff", paddingTop:"6rem", textAlign:"center", margin:"auto", width: "60rem", height:"25rem", border:"5px dashed #bbb" }}
            >
                {files.length ? 
                <>
                    <button
                        disabled={isLoading}
                        style={{ fontWeight:"bold", float:"right"}}
                        className='button is-danger is-outlined'
                        onClick={() => setFiles([])}
                    >
                        <FontAwesomeIcon icon={faTrash} />&nbsp;
                        CANCELAR
                    </button>
                    <button
                        disabled={isLoading}
                        style={{ fontWeight:"bold", float:"right"}}
                        className='button is-info is-outlined mr-2'
                        onClick={() => uploadFiles()}
                    >
                        <FontAwesomeIcon icon={faUpload} />&nbsp;
                        CARGAR
                    </button>

                    <ul className='pt-6'>
                        {fileListToArray(files).map(file => (
                            <li><FontAwesomeIcon className="has-text-success" style={{ fontSize:"1.3rem", margin:"0 1rem" }} icon={faImage} />  {file.name}</li>
                        ))}
                    </ul>
                </>
                : <center>
                    <label 
                        for="fileInput"
                        style={{ 
                            padding: "10rem",
                            textAlign: "center",
                            lineHeight: "25rem",
                            border: "5px dashed #bbb",
                            cursor: "pointer",
                            color: "#bbb"
                        }}
                        onDragOver={e => preventDefaults(e)}
                        onDragEnter={e => preventDefaults(e)}
                        onDragLeave={e => preventDefaults(e)}
                        onDrop={e => processFiles(e, e.dataTransfer.files)}
                    >
                        <span style={{ fontSize: "2rem" }}>Arrastre y suelte sus fotos aquí</span>
                    </label>
                </center>}   
                <input id="fileInput" type="file" accept="image/png, image/jpeg" files={files} multiple hidden onChange={e => processFiles(e, e.target.files)}/>
            </div>}

            {activeTab === "gallery" && <Gallery content={galleryContent}/>}
        </div>
    </div>);
}