import { Title, Card, Image, Table } from 'reactive-bulma';
import teams from './teams.json';
import players from './players.json';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faImage, faMap } from '@fortawesome/free-solid-svg-icons';
import { tabStyle } from './matches';
import { Gallery } from '../../../components/gallery';
import Img1 from '../../../assets/fmf/1.jpg';
import Img2 from '../../../assets/fmf/2.jpeg';
import Img3 from '../../../assets/fmf/3.jpeg';
import Img4 from '../../../assets/fmf/4.jpeg';
import Img5 from '../../../assets/fmf/5.jpg';
import Img6 from '../../../assets/fmf/6.jpeg';
import Img7 from '../../../assets/fmf/7.jpeg';
import Img8 from '../../../assets/fmf/8.jpeg';
import Img9 from '../../../assets/fmf/9.jpeg';
import Img10 from '../../../assets/fmf/10.jpg';
import Img11 from '../../../assets/fmf/11.jpeg';
import Img12 from '../../../assets/fmf/12.jpeg';
import Img13 from '../../../assets/fmf/13.jpeg';
import Img14 from '../../../assets/fmf/14.jpeg';
import Img15 from '../../../assets/fmf/15.jpeg';
import Img16 from '../../../assets/fmf/16.jpeg';

export const Advices = () => {
    const [isMobile] = useState(!window.matchMedia("(min-width: 768px)").matches);
    const [activeTab, setActiveTab] = useState("map");
    const galleryContent = [
        Img1,
        Img2,
        Img3,
        Img4,
        Img5,
        Img6,
        Img7,
        Img8,
        Img9,
        Img10,
        Img11,
        Img12,
        Img13,
        Img14,
        Img15,
        Img16,
    ];

    return(<div className="has-text-dark" style={{
        position: "absolute",
        width:"100%",
        top: isMobile ? "11rem":"8rem",
        left:"0",
        color: "#000",
        textAlign:"left"
    }}>
        <div className='container'>

            <a 
                className='button is-info is-outlined mb-5'
                href="/assets/fmfmapa.jpg" download={`mapa-fmf.jpg`}
            >
                <FontAwesomeIcon style={{ fontSize:"1.3rem", margin:"0 1rem" }} icon={faMap} /> Descarga el mapa del evento.
            </a>

            <div class="tabs">
                <ul style={{ borderBottom: "1px solid #ddd" }}>
                    <li
                        className={activeTab === "map" && 'is-active'} 
                        style={{ color: activeTab === "map" ? "#3e8ed0" : "#4a4a4a" }}
                        onClick={() => setActiveTab('map')}
                    >
                        <span style={activeTab === "map" ? {...tabStyle, borderBottom:"1px solid #3e8ed0" } : tabStyle}>
                            <FontAwesomeIcon style={{ fontSize:"1.3rem", margin:"0 1rem" }} icon={faMap} />
                            Mapa del Evento
                        </span>
                    </li>
                    
                    <li
                        className={activeTab === "gallery" && 'is-active'} 
                        style={{ color: activeTab === "gallery" ? "#3e8ed0" : "#4a4a4a" }}
                        onClick={() => setActiveTab('gallery')}
                    >
                        <span style={activeTab === "gallery" ? {...tabStyle, borderBottom:"1px solid #3e8ed0" } : tabStyle}>
                            <FontAwesomeIcon style={{ fontSize:"1.3rem", margin:"0 1rem" }} icon={faImage} />    
                            Conoce la Federación Mexicana de Futbol
                        </span>
                    </li>
                </ul>
            </div>

            {activeTab === "map" && <Card
                content={
                    <Image src="/assets/fmfmapa.jpg"
                        style={{
                            maxHeight: "110vh",
                            width:"auto",
                            margin:"auto",
                        }}
                    />
                }
            />}

            {activeTab === "gallery" && <Gallery content={galleryContent}/>}
        </div>
    </div>);
}