import { Hero, Breadcrumbs, Image } from 'reactive-bulma';
import { Matches } from './components/matches';
import { Cup } from './components/cup';
import { useState } from 'react';
import { Teams } from './components/teams';
import { Advices } from './components/advices';
import { Rules } from './components/rules';
import { GalleryView } from './components/gallery';

export const Dashboard = () => {
    const [activeSection, setActiveSection] = useState("Inicio");
    const [isMobile] = useState(!window.matchMedia("(min-width: 768px)").matches);


    let headStyle = {
        light: isMobile ? {
            position: "absolute",
            zIndex: "10000",
            width: "100vw",
            height: "12rem",
            background: "linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgb(0 0 0 / 7%) 10%, rgb(0 0 0 / 70%) 100%)",
            color: "rgb(255, 255, 255)"
        } :        
        {
            position: "absolute",
            zIndex: "10000",
            width: "100vw",
            background: "linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgb(138 138 138 / 13%) 10%, rgb(0 0 0 / 39%) 100%)",
            color: "#fff"
        },
        dark: isMobile ? {
            position: "absolute",
            width: "100vw",
            height: "9rem",
            background: "linear-gradient(315deg, rgba(140,82,255,1) 0%, rgba(94,23,235,1) 64%, rgba(41,109,253,1) 100%)",
            color: "rgb(255, 255, 255)"
        } : {
            position: "absolute",
            width: "100vw",
            background: "linear-gradient(315deg, rgba(140,82,255,1) 0%, rgba(94,23,235,1) 64%, rgba(41,109,253,1) 100%)",
            color: "#fff"
        }
    }



    const sections = {
        Inicio: <Cup />,
        Mapa: <Advices />,
        Reglamento: <Rules />,
        Partidos: <Matches />,
        Equipos: <Teams />,
        Gallery: <GalleryView />,
    }


    return (<>
        <section
            style={{
                minHeight: '100%',
                minWidth: '100%',
                background: '#fff',
                position: 'fixed'
            }}
        ></section>
        <Hero
            header={<div
                style={activeSection === 'Inicio' ? headStyle.light : headStyle.dark}
            >
                <Breadcrumbs style={{
                    position: "relative",
                    margin: "1rem 2rem",
                    fontWeight: "bold",
                    fontSize: "2rem",
                }} items={[
                    { isActiveItem: true, text: 'Sporti' },
                ]} />

                <Breadcrumbs separator={"has-dot-separator"} style={{
                    position: "absolute",
                    left: isMobile ? "0.5rem" : "calc(50% - 20rem)",
                    top: isMobile ? "5rem" : "2rem",
                    width: "100%",
                    fontSize: "1.2rem",
                }} items={[
                    { isActiveItem: activeSection === 'Inicio', text: 'Inicio', onClick: () => setActiveSection('Inicio') },
                    { isActiveItem: activeSection === 'Mapa', text: 'Mapa del Evento', onClick: () => setActiveSection('Mapa') },
                    { isActiveItem: activeSection === 'Reglamento', text: 'Reglamento', onClick: () => setActiveSection('Reglamento') },
                    { isActiveItem: activeSection === 'Partidos', text: 'Partidos', onClick: () => setActiveSection('Partidos') },
                    { isActiveItem: activeSection === 'Equipos', text: 'Equipos', onClick: () => setActiveSection('Equipos') },
                    { isActiveItem: activeSection === 'Gallery', text: 'Galería', onClick: () => setActiveSection('Gallery') },

                ]} />

                <section
                    style={{
                        position: "absolute",
                        top: "15px",
                        right: "3rem",
                        maxHeight: '4rem',
                        maxWidth: '4rem'
                    }}
                >
                    <Image src="/assets/ppg_lg_rgb_rev.png" />

                </section>
            </div>
            }
            body={
                <section
                    style={{
                        width: "100vw"
                    }}
                >
                    {sections[activeSection]}
                </section>
            }
            size="is-fullheight"
            color={activeSection === "Inicio" ? 'is-dark' : 'is-light'}
        />
    </>);
}