import { Api } from "../../../../lib/api";

const RequestMethod = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    PATCH: 'PATCH',
    DELETE:'DELETE',
}
  

export class SportiService extends Api{
    constructor(auth_token){
        super(auth_token);
    }

    getMatches(){
        return this.api({
            method: RequestMethod.GET,
            url: "/matches"
        })
        .then(response => response.data);
    }
    getSchedules(){
        return this.api({
            method: RequestMethod.GET,
            url: "/schedules"
        })
        .then(response => response.data);
    }
    generateMatches(data){
        return this.api({
            method: RequestMethod.POST,
            url: "/matches/make",
            data
        })
        .then(response => response.data);
    }

    getTeams(){
        return this.api({
            method: RequestMethod.GET,
            url: "/teams"
        })
        .then(response => response.data);
    }
    
    setMatches(match){
        return this.api({
            method: RequestMethod.POST,
            url: "/matches",
            data: { ...match }
        })
        .then(response => response.data);
    }
    setSchedules(schedule, TeamId){
        return this.api({
            method: RequestMethod.POST,
            url: "/schedules",
            data: { ...schedule, TeamId }
        })
        .then(response => response.data);
    }
    
    rateMatch(match){
        return this.api({
            method: RequestMethod.POST,
            url: "/matches/rate",
            data: { ...match }
        })
        .then(response => response.data);
    }
    
    getImages(){
        return this.api({
            method: RequestMethod.GET,
            url: "/gallery"
        })
        .then(response => response.data);
    }

    postImages(images){
        return this.api({
            method: RequestMethod.POST,
            url: "/gallery",
            data: { images }
        })
        .then(response => response.data);
    }
}