import React, {useState} from "react";
import { Card, Image } from "reactive-bulma";

export const Gallery = ({ content }) => {
    const [showModal, setShowModal] = useState(); 

    return (<div className="columns is-multiline mt-5">
        {content.map(image => <div class="column is-one-quarter">
            <img
                style={{
                    cursor: "pointer",
                    boxShadow: "10px 10px 21px -4px rgba(0,0,0,0.38)",
                    borderRadius:"5%",
                    height: "13rem",
                }}
                src={image}
                onClick={() => setShowModal(image)}
            />
        </div>)}

        <>
            <div class={`modal ${showModal && 'is-active'}`}>
                <div class="modal-background"></div>
                <div class="modal-content" style={{ maxHeight:"90vh", width:"auto" }}>
                    <Image src={showModal} style={{ height: "95vh", width: "auto", borderRadius:"2%" }}/>
                </div>
                
                <button class="modal-close is-large" aria-label="close" onClick={() => setShowModal()}/>
            </div>
        </>
    </div>);
}