import './App.css';
import { HomePage } from './views/homepage';
import { Dashboard } from './views/dashboard';
import {
  HashRouter as Router,
  Routes as Switch,
  Route,
} from "react-router-dom";


function App() {
  return (
    <div className="App">
      <Router basename='/'>
        <div>
          {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
          <Switch>
            <Route path="/ppg" Component={Dashboard}>
            </Route>
            <Route path="/" Component={HomePage}>
            </Route>
          </Switch>
        </div>
      </Router>
    </div>
  );
}

export default App;
